export const pageLink = {
  home: "/",
  developers: "/developers",
  community: "/community",
  about: "/about",
  aboutMission: "/about/#mission",
  aboutValues: "/about/#values",
  aboutVision: "/about/#vision",
  aboutTech: "/about/#tech",
  sellers: "/sellers",
  sellersGetStarted: "/sellers/#hero",
  sellersFeatures: "/sellers/#features",
  sellersSpaces: "/sellers/#spaces",
  whitepaper: "/",
  token: "/",
  docs: "/developers",
  github: "/developers",
  stackOverflow: "https://stackoverflow.com/users/19389748/double",
  twitter: "https://twitter.com/double_stores",
  discord: "https://discord.gg/double-store",
  youtube: "https://www.youtube.com/channel/UCqDxVBGKU7RfaG9Nut9fQCQ/featured",
  medium: "https://medium.com/@Perigee",
  telegram: "https://t.me/Double_Stores",
  tokenomics: "/",
  reddit:"https://www.reddit.com/r/double_store/",
  tedx: "https://www.tedxchaterroad.com/",
  store: "https://app.double.store/onboarding",
  terms: "/terms",
  email: "/",
  contact: "/",
  youtubeId: "O6yotj8opA0"
}

export const demoLink = "kUZXOKr4EAc"

export const conversionId = {
  discord: "AW-10975360890/0HE4CJ3n24cYEPruuvEo",
  email: "AW-10975360890/XOMyCKzn24cYEPruuvEo",
  exploreTheDocs: "AW-10975360890/ddnkCJHn24cYEPruuvEo",
  getStarted: "AW-10975360890/ZvjECMqE3YcYEPruuvEo",
  getStarted1: "AW-10975360890/51LpCI7n24cYEPruuvEo",
  github: "AW-10975360890/uw00CKDn24cYEPruuvEo",
  medium: "AW-10975360890/Gf64CKPn24cYEPruuvEo",
  stackOverflow: "AW-10975360890/AEqmCKnn24cYEPruuvEo",
  startNow: "AW-10975360890/JmM-CLLL2ocYEPruuvEo",
  telegram: "AW-10975360890/vuT3CJfn24cYEPruuvEo",
  readArticle: "AW-10975360890/UT28CK_n24cYEPruuvEo",
  twitter: "AW-10975360890/WD3QCJrn24cYEPruuvEo",
  visitUsOnGithub: "AW-10975360890/PltWCJTn24cYEPruuvEo",
  watchVideo: "AW-10975360890/Gpf1CMeE3YcYEPruuvEo",
  youtube: "AW-10975360890/GZmvCKbn24cYEPruuvEo",
}

export const googleConversion = (id) => {
  if (window.gtag && id !== undefined && process.env.NODE_ENV==="production") {
    // // console.log('window.gtag ------- ', window.gtag)
    window.gtag(
      'event', 
      'conversion', 
      { 
        'send_to': id,
      }
    );
  }
}
import React from 'react'
import { Link } from "gatsby"
import { useLocation } from '@reach/router';
import './index.scss'

import { pageLink } from '../../utils'


const Footer = () => {
  const location = useLocation();
  return (
    <footer className={`footer`}>
      <div className={`footer__border-top ${ location.pathname!=="/developers/" && "show"}`}></div>
      <div className={`footer__container`}>
        <div className="footer-title">Navigate Double</div>
        <div className="footer__container__wrapper">
        <div className="footer-item developers">
            <h3>SELLERS & CREATORS</h3>
            <nav>
              <Link to={ pageLink.sellersGetStarted }>Get Started</Link>
              <Link to={ pageLink.sellersFeatures }>Features</Link>
              <Link to={ pageLink.sellersSpaces }>Metaverse Spaces</Link>
            </nav>
          </div>
          {/* <div className="footer-item developers">
            <h3>DEVELOPERS</h3>
            <nav> */}
            {/* <a href={ pageLink.docs } target="_blank" rel="noreferrer" aria-label="docs" >Docs</a> */}
            {/* <a href={ pageLink.github } target="_blank" rel="noreferrer" aria-label="github" >GitHub</a> */}
              {/* <Link to={ pageLink.docs }>Docs</Link>
              <Link to={ pageLink.github }>GitHub</Link>
              <a href={ pageLink.stackOverflow } target="_blank" rel="noreferrer" aria-label="stackoverflow" >StackOverflow</a>
            </nav>
          </div> */}
          <div className="footer-item community">
            <h3>COMMUNITY</h3>
            <nav className="community">
              <a href={ pageLink.discord } target="_blank" rel="noreferrer" aria-label="Discord">Discord</a>
              <a href={ pageLink.twitter } target="_blank" rel="noreferrer" aria-label="Twitter">Twitter</a>
              <a href={ pageLink.youtube } target="_blank" rel="noreferrer" aria-label="Youtube">Youtube</a>
              <a href={ pageLink.telegram } target="_blank" rel="noreferrer" aria-label="Telegram">Telegram</a>
              <a href={ pageLink.medium } target="_blank" rel="noreferrer" aria-label="Medium">Medium</a>
              {/* <a href={ pageLink.reddit } target="_blank" rel="noreferrer" aria-label="Reddit">Reddit</a> */}
            </nav>
          </div>
          <div className="footer-item about">
            <h3>ABOUT</h3>
            <nav>
              <Link to={ pageLink.aboutMission }>Mission</Link>
              <Link to={ pageLink.aboutValues }>Values</Link>
              {/* <Link to={ pageLink.whitepaper }>Whitepaper</Link> */}
              <Link to={ pageLink.aboutVision }>Vision</Link>
              <Link to={ pageLink.aboutTech }>Tech</Link>
              {/* <a href={ pageLink.doubleTech } target="_blank" rel="noreferrer" aria-label="sellers hub">Tech</a> */}
            </nav>
          </div>
          <div className="footer-item other">
            <h3>OTHER</h3>
            <nav>
              <Link to={ pageLink.contact }>Contact</Link>
              <Link to={ pageLink.terms }>Terms & Condition</Link>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer

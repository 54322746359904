import React from 'react'
import { Link } from 'gatsby'

import { pageLink } from '../../utils'

import doubleLogoImg from '../../assets/images/double-logo.png';

import RedditSvg from '../../assets/svg/reddit.svg';
import DiscordSvg from '../../assets/svg/discord_32.svg';
import TwitterSvg from '../../assets/svg/twitter_32.svg';
import TelegramSvg from '../../assets/svg/telegram_32.svg';
import CrossSvg from '../../assets/svg/close-cross.svg';
import MenuHamburgerSvg from '../../assets/svg/menu-hamburger.svg';


import './index.scss'


const TopNav = ({ 
  showMobileMenu,
  handleMenuButtonClick,
  handleCloseButtonClick
}) => {  
  return (
    <>
      <div className="top-nav">
        {/* <div className="top-nav__top-bar">
          <nav className="top-nav__top-bar__inner"> */}
            {/* <a className="desktop-link" href={pageLink.doubleStore} target="_blank" rel="noreferrer" aria-label="sign up">SIGNUP</a> */}
            {/* <Link className="desktop-link" to={pageLink.whitepaper}>WHITEPAPER</Link>
            <Link className="desktop-link" to={pageLink.token}>TOKEN</Link> */}
            {/* <button 
              className="menu-hamburger" 
              onClick={handleMenuButtonClick}
              aria-label="menu open"
            >
              <MenuHamburgerSvg />
            </button> */}
          {/* </nav>
        </div> */}
        <div className="nav-bar-wrapper">
          <div className="top-nav__nav-bar">
            <div className="top-nav__nav-bar--left">
              <Link className="no-hover-color" to={ pageLink.home }>
                <img className="site-logo" src={doubleLogoImg} alt="logo" />
              </Link>
            </div>
            <div className="top-nav__nav-bar--right">
              <Link className="desktop-link" activeClassName="active" to={ pageLink.sellers }>Sellers & Creators</Link>
              {/* <Link className="desktop-link" activeClassName="active" to={ pageLink.developers }>Developers</Link> */}
              <Link className="desktop-link" activeClassName="active" to={ pageLink.community }>Community</Link>
              <Link className="desktop-link" activeClassName="active" to={ pageLink.about }>About</Link>
              
              <a className="icon-link telegram" href={pageLink.telegram} target="_blank" rel="noreferrer" aria-label="Telegram"><TelegramSvg /></a>
              <a className="icon-link twitter" href={ pageLink.twitter } target="_blank" rel="noreferrer" aria-label="Twitter"><TwitterSvg /></a>
              <a className="icon-link discord" href={ pageLink.discord } target="_blank" rel="noreferrer" aria-label="Discord"><DiscordSvg /></a>
            </div>
          </div>
        </div>
      </div>
      <Link className={`top-nav--mobile-logo`} to={ pageLink.home }>
        <img src={doubleLogoImg} alt="logo" />
      </Link>
      <button 
        className="top-nav--menu-hamburger" 
        onClick={handleMenuButtonClick}
        aria-label="menu open"
      >
        <MenuHamburgerSvg />
      </button>
      <div className={`top-nav--mobile ${showMobileMenu ? "mobile-open" : ""}`}>
        <button 
          className="close-button" 
          onClick={handleCloseButtonClick}
          aria-label="menu close"
        >
          <CrossSvg />
        </button>
        <a className="top-nav--mobile__link--big-text" href={ pageLink.sellers }>SELLERS & CREATORS</a>
        {/* <a className="top-nav--mobile__link--big-text" href={ pageLink.developers }>DEVELOPERS</a> */}
        <a className="top-nav--mobile__link--big-text" href={ pageLink.community }>COMMUNITY</a>
        <a className="top-nav--mobile__link--big-text" href={ pageLink.about }>ABOUT</a>
        {/* <a className="top-nav--mobile__lin--big-text" href={pageLink.doubleStore} target="_blank" rel="noreferrer" aria-label="sign up">SIGNUP</a> */}
        {/* <a className="top-nav--mobile__link--small-text" href={pageLink.token}>DOUBLE TOKEN</a> */}
        {/* <a className="top-nav--mobile__link--small-text" href={pageLink.whitepaper}>WHITEPAPER</a> */}
        <div className="top-nav--mobile__link-container">
          <a className="icon-link telegram" href={pageLink.telegram} target="_blank" rel="noreferrer" aria-label="Telegram"><TelegramSvg className="telegram" /></a>
          <a className="icon-link twitter" href={pageLink.twitter} target="_blank" rel="noreferrer" aria-label="Twitter"><TwitterSvg className="twitter" /></a>
          <a className="icon-link discord" href={pageLink.discord} target="_blank" rel="noreferrer" aria-label="Discord"><DiscordSvg className="discord" /></a>
        </div>
      </div>
    </>
  )
}

export default TopNav

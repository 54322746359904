import React, { useState } from 'react'
import { Helmet } from "react-helmet"

import TopNav from '../TopNav'
import Footer from '../Footer'



import './index.scss'

const Layout = ({ title, children, pageClassName, description }) => {
  const [ showMobileMenu, setShowMobileMenu ] = useState( false );

  const handleMenuButtonClick = () => {
    setShowMobileMenu(true) 
  }

  const handleCloseButtonClick = () => {
    setShowMobileMenu(false)
  }
  return (
    <>
      <Helmet 
        title={ title }
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <link rel="preconnect" href="https://fonts.googleapis.com"/>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
        <link href="https://fonts.googleapis.com/css2?family=Lato&family=Poppins:wght@400;500;600;700&display=swap" rel="stylesheet" />
        <meta name="description" content={description} />
        <meta name="google-site-verification" content="zMN3gmQQAo3CeW7owIKxskxwQ8o6fH4HMqxKgmclm_s" /> 
      </Helmet>
      <TopNav 
        showMobileMenu={ showMobileMenu }
        handleMenuButtonClick={ handleMenuButtonClick }
        handleCloseButtonClick={ handleCloseButtonClick }
      />
      <main className={`${pageClassName} ${showMobileMenu ? "position-fixed cover-all" : ""} page`}>
        {children}
      </main>
      <Footer />
    </>
  )
}

export default Layout